.home {
  display: flex;

  .homeContainer {
    margin-top: 20px;
    flex: 6;

    .widgets,
    .charts {
      display: flex;
      padding: 20px;
      gap: 20px;
    }

    .charts {
      padding: 5px 20px;
    }

    .listContainer {
      -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
      box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
      padding: 20px;
      margin: 20px;

      .listTitle {
        font-weight: 500;
        color: gray;
        margin-bottom: 15px;
      }
    }
  }
}


.ant-table {
  margin: 20px auto;
  max-width: 90vw;
}


.ant-table-wrapper {
  border: 1px solid #e8e8e8;
  border-radius: 5px;
}


.ant-input {
  border-radius: 5px;
  border: 1px solid #e8e8e8;
}



.ant-dropdown-link {
  color: #1890ff;
}
.ant-btn {
  border-radius: 5px;
}
.ant-btn-primary {
  background-color: #1890ff;
  border-color: #1890ff;
}
.ant-btn-link {
  color: #1890ff;
  border: none;
  padding: 0;
}
.ant-btn-link:hover {
  border: none;
  color: #40a9ff;
  text-decoration: underline;
  background-color: transparent;
}



.ant-table-row {
  transition: background-color 0.3s ease;
}
.ant-table-row:hover {
  background-color: #f2f2f2;
}
.ant-table-cell {
  padding: 12px 16px;
}


.ant-table-thead > tr > th {
  background-color: #f5f5f5;
  font-weight: bold;
  text-transform: uppercase;
}
body {
  font-family: 'Helvetica Neue', sans-serif;
  font-size: 16px;
}



// .home {
//   display: flex;
//   flex-direction: column;
//   width: 80vw;
//   margin: 20px auto;
// }
